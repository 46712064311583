import { render, staticRenderFns } from "./ContentMarketing.vue?vue&type=template&id=68247b54&scoped=true&"
import script from "./ContentMarketing.vue?vue&type=script&lang=ts&"
export * from "./ContentMarketing.vue?vue&type=script&lang=ts&"
import style0 from "./ContentMarketing.vue?vue&type=style&index=0&id=68247b54&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68247b54",
  null
  
)

export default component.exports